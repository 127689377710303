<template>
  <div id="app">
    <template v-if="isCheckingUser">
      <img class="init-loading-icon" alt="Loading..." src="./assets/images/loading.gif" />
    </template>
    <div v-else>
      <bf-row v-if="!loged" type="flex" justify="center" class="mt-l">
        <bf-col :span="10" :mobile="24" :smallTablet="24" :tablet="10" :largeTablet="10">
          <div class="text-center">
            <img class="banner-login" alt="Bizfly logo" src="./assets/images/Logo.svg" />
          </div>
          <div class="mt-l">
            <bf-form ref="loginForm" :model="loginForm" :rules="loginFormRules">
              <bf-form-item label="Email" prop="email">
                <bf-input :disabled="isLoging" v-model="loginForm.email"></bf-input>
              </bf-form-item>
              <bf-form-item label="Password" prop="password">
                <bf-input
                  :disabled="isLoging"
                  v-model="loginForm.password"
                  :type="togglePassword ? 'text' : 'password'"
                >
                  <span @click="togglePassword = !togglePassword" slot="suffix">
                    <bf-icon
                      :name="togglePassword ? 'eye-off-outline' : 'eye-outline'"
                      class="cursor-pointer"
                    />
                  </span>
                </bf-input>
              </bf-form-item>
            </bf-form>
            <bf-button :loading="isLoging" @click="handleLogin()" type="primary">Login</bf-button>
          </div>
        </bf-col>
      </bf-row>
      <div v-else>
        <div v-show="$route.name !== 'LandingpageEditUiView'" class="header-navigation">
          <bf-row type="flex" justify="space-between" align="middle">
            <bf-row type="flex" align="middle">
              <menu-list />
              <router-link to="/" class="ml-2xl"
                ><img class="logo" alt="Bizfly logo" src="./assets/images/Logo.svg"
              /></router-link>
            </bf-row>
            <bf-dropdown placement="bottom-end" @command="handleCommandUser">
              <bf-row type="flex" align="middle">
                <span v-if="screenWidth > 600" class="mr-2xs text-green">{{ me?.email }}</span>
                <bf-avatar :size="20" alt="avatar" :name="me?.email?.slice(0, 1) || 'A'" />
              </bf-row>
              <bf-dropdown-menu slot="dropdown">
                <div class="bf-header__service-group">
                  <div class="group">
                    <bf-dropdown-item :command="`/users/detail/${me?.$id}`" icon="address-book"
                      >Thông tin tài khoản</bf-dropdown-item
                    >
                    <bf-dropdown-item command="logout" icon="turn-off" divided
                      >Đăng xuất</bf-dropdown-item
                    >
                  </div>
                </div>
              </bf-dropdown-menu>
            </bf-dropdown>
          </bf-row>
        </div>
        <router-view
          :class="[
            {
              'm-xl': $route.name !== 'LandingpageEditView' && screenWidth > 992,
            },
            {
              'mx-s my-l': $route.name !== 'LandingpageEditView' && screenWidth <= 992,
            },
          ]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import MenuList from './components/menu/MenuList.vue';
import axios from './utils/request';
import userService from './services/user';

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      loginForm: { email: '', password: '' },
      loginFormRules: {
        email: [
          { required: true, message: 'Email không được để trống', trigger: 'blur' },
          {
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Email không đúng định dạng',
            trigger: ['blur', 'change'],
          },
        ],
        password: [{ required: true, message: 'Password không được để trống', trigger: 'blur' }],
      },
      togglePassword: false,
      isCheckingUser: true,
      loged: false,
      isLoging: false,
    };
  },
  created() {
    this.UPDATE_SCREEN_WIDTH(window.innerWidth);
    this.setWidthScreen();
    this.checkLoged();
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('screen', ['screenWidth']),
  },
  methods: {
    ...mapActions('user', ['updateMe']),
    ...mapMutations('screen', ['UPDATE_SCREEN_WIDTH']),
    setWidthScreen() {
      window.addEventListener('resize', () => {
        this.UPDATE_SCREEN_WIDTH(window.innerWidth);
      });
    },
    async checkLoged() {
      if (localStorage.getItem('token')) {
        try {
          const res = await userService.getMe();
          if (res?.data?.data) {
            this.updateMe(res?.data?.data);
          }
          this.isCheckingUser = false;
          this.loged = true;
        } catch (error) {
          localStorage.removeItem('token');
          window.location.reload();
        }
      } else {
        this.loged = false;
        this.isCheckingUser = false;
      }
    },
    async handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.isLoging = true;
          try {
            const res = await userService.login({
              email: this.loginForm.email,
              password: this.loginForm.password,
            });
            if (res?.data?.data?.a_session_landingpage) {
              localStorage.setItem('token', res?.data?.data?.a_session_landingpage);
              axios.defaults.headers.common.Authorization = `bearer ${res?.data?.data?.a_session_landingpage}`;
              await this.checkLoged();
            }
            this.isLoging = false;
          } catch (error) {
            this.isLoging = false;
          }
        }
      });
    },
    async handleCommandUser(command) {
      if (command === 'logout') {
        await this.logoutInServer();
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      this.$router.push(command);
    },
    async logoutInServer() {
      await userService.logout();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-navigation {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 15px 20px;
}
.init-loading-icon {
  width: 200px;
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @media only screen and (max-width: 600px) {
    width: 150px;
  }
}
</style>
