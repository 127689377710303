import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './middleware';
import store from './store';
import * as filters from './utils/filters';
import './assets/css/index.scss';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapick/dist/grapick.min.css';

import {
  BfAlert,
  BfAutocomplete,
  BfAvatar,
  BfBadge,
  BfBreadcrumb,
  BfBreadcrumbItem,
  BfButton,
  BfCard,
  BfCarousel,
  BfCarouselItem,
  BfCheckbox,
  BfCheckboxGroup,
  BfCol,
  BfCollapse,
  BfCollapseItem,
  BfDatePicker,
  BfDialog,
  BfDropdown,
  BfDropdownItem,
  BfDropdownMenu,
  BfDropdownSubmenu,
  BfForm,
  BfFormItem,
  BfIcon,
  BfInput,
  BfInputNumber,
  BfLink,
  BfMenu,
  BfMenuItem,
  BfMenuItemGroup,
  BfOption,
  BfOptionGroup,
  BfPagination,
  BfPopover,
  BfProgress,
  BfRadio,
  BfRadioGroup,
  BfRadioTitle,
  BfRow,
  BfSelect,
  BfStatus,
  BfStep,
  BfSteps,
  BfSubmenu,
  BfSwitch,
  BfTable,
  BfTableColumn,
  BfTag,
  BfTimePicker,
  BfTooltip,
  BfTree,
  BfUpload,
  BfDrawer,
  Notification,
  languages,
  locale,
} from './assets/js/index.min.js';

Vue.component('BfAlert', BfAlert);
Vue.component('BfAutocomplete', BfAutocomplete);
Vue.component('BfAvatar', BfAvatar);
Vue.component('BfBadge', BfBadge);
Vue.component('BfBreadcrumb', BfBreadcrumb);
Vue.component('BfBreadcrumbItem', BfBreadcrumbItem);
Vue.component('BfButton', BfButton);
Vue.component('BfCard', BfCard);
Vue.component('BfCarousel', BfCarousel);
Vue.component('BfCarouselItem', BfCarouselItem);
Vue.component('BfCheckbox', BfCheckbox);
Vue.component('BfCheckboxGroup', BfCheckboxGroup);
Vue.component('BfCol', BfCol);
Vue.component('BfCollapse', BfCollapse);
Vue.component('BfCollapseItem', BfCollapseItem);
Vue.component('BfDatePicker', BfDatePicker);
Vue.component('BfDialog', BfDialog);
Vue.component('BfDropdown', BfDropdown);
Vue.component('BfDropdownItem', BfDropdownItem);
Vue.component('BfDropdownMenu', BfDropdownMenu);
Vue.component('BfDropdownSubmenu', BfDropdownSubmenu);
Vue.component('BfForm', BfForm);
Vue.component('BfFormItem', BfFormItem);
Vue.component('BfIcon', BfIcon);
Vue.component('BfInput', BfInput);
Vue.component('BfInputNumber', BfInputNumber);
Vue.component('BfLink', BfLink);
Vue.component('BfMenu', BfMenu);
Vue.component('BfMenuItem', BfMenuItem);
Vue.component('BfMenuItemGroup', BfMenuItemGroup);
Vue.component('BfOption', BfOption);
Vue.component('BfOptionGroup', BfOptionGroup);
Vue.component('BfPagination', BfPagination);
Vue.component('BfPopover', BfPopover);
Vue.component('BfProgress', BfProgress);
Vue.component('BfRadio', BfRadio);
Vue.component('BfRadioGroup', BfRadioGroup);
Vue.component('BfRadioTitle', BfRadioTitle);
Vue.component('BfRow', BfRow);
Vue.component('BfSelect', BfSelect);
Vue.component('BfStatus', BfStatus);
Vue.component('BfStep', BfStep);
Vue.component('BfSteps', BfSteps);
Vue.component('BfSubmenu', BfSubmenu);
Vue.component('BfSwitch', BfSwitch);
Vue.component('BfTable', BfTable);
Vue.component('BfTableColumn', BfTableColumn);
Vue.component('BfTag', BfTag);
Vue.component('BfTimePicker', BfTimePicker);
Vue.component('BfTooltip', BfTooltip);
Vue.component('BfTree', BfTree);
Vue.component('BfUpload', BfUpload);
Vue.component('BfDrawer', BfDrawer);

locale(languages.vi);

Vue.prototype.$notify = Notification;

Vue.config.productionTip = false;

const mergeFilters = { ...filters };
Object.keys(mergeFilters).forEach((key) => {
  Vue.filter(key, mergeFilters[key]);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
