const state = {
  me: {},
  loadedMe: false,
};

const getters = {
  me(state) {
    return state.me;
  },
  role(state) {
    const checkIsAdmin = state?.me?.memberships?.findIndex((member) => member.teamName === 'admin');
    if (checkIsAdmin >= 0) {
      return 'admin';
    }
    const checkIsLeader = state?.me?.memberships?.findIndex(
      (member) => member.teamName === 'leader',
    );
    if (checkIsLeader >= 0) {
      return 'leader';
    }
    const checkIsEditor = state?.me?.memberships?.findIndex(
      (member) => member.teamName === 'editor',
    );
    if (checkIsEditor >= 0) {
      return 'editor';
    }
    return 'viewer';
  },
};

const mutations = {
  UPDATE_ME(state, payload) {
    state.me = payload;
    state.loadedMe = true;
  },
};

const actions = {
  updateMe({ commit }, payload) {
    commit('UPDATE_ME', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
