import router from './router/index';
import store from './store';

router.beforeEach(async (to, from, next) => {
  while (!store.state?.user?.loadedMe) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((r) => setTimeout(r, 100));
  }
  if (to?.meta?.requiredRoles) {
    const checkPermission = to?.meta?.requiredRoles?.findIndex((role) => {
      return role === store.getters['user/role'];
    });
    if (checkPermission >= 0) {
      next();
    }
  } else {
    next();
  }
});
