export function lowerCase(text) {
  return text.toLowerCase();
}

export function localDateTime(date) {
  if (!date) return '';
  return new Date(date)?.toLocaleString('vi');
}

export function bytesToSize(byte, fixed) {
  const specified = fixed || 2;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (!byte || byte <= 1) return '0 B';
  const i = Number(Math.floor(Math.log(byte) / Math.log(1024)), 10);
  if (i === 0) return `${byte} ${sizes[i]}`;
  return `${parseFloat((byte / 1024 ** i).toFixed(specified))} ${sizes[i]}`;
}
