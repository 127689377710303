import axios from 'axios';
import { Notification } from '../assets/js/index.min.js';

const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common.Authorization = `bearer ${token}`;
}

axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error?.response?.data?.message || error?.response?.data?.detail || 'Lỗi từ server';
    Notification(
      {
        title: `Có lỗi ${error?.response?.status}`, message, customClass: 'error', type: 'error',
      },
    );
    return Promise.reject(error);
  },
);

export default axios;
