const state = {
  screenWidth: 0,
};

const getters = {
  screenWidth(state) {
    return state.screenWidth;
  },
};

const mutations = {
  UPDATE_SCREEN_WIDTH(state, payload) {
    state.screenWidth = payload;
  },
};

const actions = {
  updateScreenWidth({ commit }, payload) {
    commit('UPDATE_SCREEN_WIDTH', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
