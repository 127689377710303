import axios from '../utils/request';
import endpoint from '../config/endpoint';

async function getMe() {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users/info`,
    method: 'get',
  });
}

async function login(payload) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/account/login`,
    method: 'post',
    data: payload,
  });
}

async function logout() {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/account/logout`,
    method: 'delete',
  });
}

async function getUserList(params) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users`,
    method: 'get',
    params,
  });
}

async function getUserDetail(id) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users/${id}`,
    method: 'get',
  });
}

async function createUser(payload) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users/create`,
    method: 'post',
    data: payload,
  });
}

async function updateUser(id, payload) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users/${id}`,
    method: 'patch',
    data: payload,
  });
}

async function changePassword(payload) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/account/update_password`,
    method: 'patch',
    data: payload,
  });
}

async function deleteUser(id) {
  return axios({
    url: `${endpoint.BACKEND_BASE_URL}/users/${id}`,
    method: 'delete',
  });
}

export default {
  getMe,
  login,
  logout,
  getUserList,
  getUserDetail,
  createUser,
  updateUser,
  changePassword,
  deleteUser,
};
